import { INovaEntity } from './base';

export interface IMessageThread extends INovaEntity {
  orgId: string;
  assetVisitId: string;
  messages?: IMessageThreadMessage[];
  expiresAt: Date;
}

export enum IMessageStatus {
  DELIVERED = 'delivered',
  FAILED = 'failed',
  SENT = 'sent',
  RECEIVED = 'received'
}

export interface IMessageThreadMessage {
  id?: string;
  timestamp: Date;
  content: string;
  mediaUrls?: string[];
  toNumber: string;
  fromNumber: string;
  status: IMessageStatus;
}

export function isMessageThreadExpired(messageThread: IMessageThread): boolean {
  return new Date() > messageThread.expiresAt;
}

import { DateTime } from 'luxon';
import { isSubInterval, ITimeIntervalList, sortByStart } from './interval';
import { splitClockIntoUnitsOfTime } from './clock-span';
import { UserRole } from './user';
import { objPropExists, removeArrayDateDuplicates } from './js-helpers';
import { IDock, IWarehouse, LuxonDateTimeFormats } from '.';

export type AvailabilityResponse = {
  dock: IDock;
  warehouse: Partial<IWarehouse>;
  request: AvailabilityRequest;
  availability: ITimeIntervalList;
  startTimes?: Date[];
};

export type OpenDatesResponse = {
  openDates: string[]; // YYYY-MM-DD
};

export type AvailabilityRequest = {
  userRole: UserRole;
  now: string;
  minCarrierLeadTime_hr: number;
  maxCarrierLeadTime_hr: number;
  excludeApptId?: string;
  start: Date;
  end: Date;
};

// This is the aggregation algorithm for the underlying Capacity availabilities - we may
// choose a different one in the future if it makes more sense.
//
// Algorithm:
// Repeatedly CONCATENATE the availability of the parent Dock with the
// availability of its children Docks, then sort the resultant interval list (by start time).
export function aggregateAvailability(
  parentAvail: ITimeIntervalList,
  childrenAvails: ITimeIntervalList[]
): ITimeIntervalList {
  let finalResult = childrenAvails.reduce((cumulativeResult, result) => {
    cumulativeResult = cumulativeResult.concat(result);
    return cumulativeResult;
  }, parentAvail);

  finalResult = sortByStart(finalResult);

  return finalResult;
}

export function extractOpenDatesFromAvailability(
  timeIntervalList: ITimeIntervalList,
  timezone: string
): string[] {
  const openDates = timeIntervalList.map(timeInterval => {
    let start = DateTime.fromJSDate(timeInterval.start, { zone: timezone });
    let end = DateTime.fromJSDate(timeInterval.end, { zone: timezone });

    if (start.minute === 59 && start.second === 59) {
      start = start.plus({ second: 1 });
    }

    if (end.minute === 0 && end.second === 0) {
      end = end.minus({ second: 1 });
    }
    // Initialize dates with the start date
    const dates = [start.toFormat(LuxonDateTimeFormats.DateDashed)];

    let calculatedDate = start;
    while (calculatedDate < end) {
      calculatedDate = calculatedDate.plus({ day: 1 });
      if (calculatedDate < end) {
        dates.push(calculatedDate.toFormat(LuxonDateTimeFormats.DateDashed));
      } else {
        dates.push(end.toFormat(LuxonDateTimeFormats.DateDashed));
      }
    }
    return dates;
  });

  return [
    ...openDates
      .flat() // Flatten openDates before reducing
      .reduce((acc, date) => {
        acc.add(date);
        return acc;
      }, new Set<string>())
  ];
}

/***
 * Use this method to return an array of startTimes for an appointment with:
 *  - a given duration
 *  - an availability interval list
 *  - and a list of custom startTimes
 *  The timezone is necessary to ensure the custom start times are for the desired warehouse timezone.
 * @param availability
 * @param customAppointmentStartTimes
 * @param timezone
 * @param durationInMinutes
 */
export function getCustomStartTimes(
  availability: ITimeIntervalList,
  customAppointmentStartTimes: string[],
  timezone: string,
  durationInMinutes: number
) {
  const startTimes: Date[] = [];

  // when using customAppointmentStartTimes, these repeat each DAY, so we must deal with the interval each day

  for (const interval of availability) {
    const startOfDay = DateTime.fromJSDate(interval.start).setZone(timezone).startOf('day');
    const endOfInterval = getEndOfIntervalRounded(interval.end).setZone(timezone);
    const roundedInterval = { start: interval.start, end: endOfInterval.toJSDate() };
    const duration = { minutes: durationInMinutes };
    const days = endOfInterval.diff(startOfDay, 'days').days + 1;

    for (let i = 0; i < days; i++) {
      const currentDay = startOfDay.plus({ days: i });

      for (const startTime of customAppointmentStartTimes) {
        const setTime = splitClockIntoUnitsOfTime(startTime);
        const start = currentDay.set(setTime).toJSDate();
        const end = currentDay.set(setTime).plus(duration).toJSDate();
        const subInterval = { start, end };
        if (isSubInterval(subInterval, roundedInterval)) {
          startTimes.push(subInterval.start);
        }
      }
    }
  }

  return startTimes;
}

/***
 * Use this method to return an array of startTimes for an appointment that does NOT have custom startTimes,
 * i.e. ['9:30', '10:15']. These appointments have expected startTimes based on clock minutes: i.e. every 20 minutes
 * @param availability
 * @param appointmentStartTimeMinutes
 * @param durationInMinutes
 */
export function getStartTimes(
  availability: ITimeIntervalList,
  appointmentStartTimeMinutes: number,
  durationInMinutes: number
) {
  const startTimes: Date[] = [];
  // fix infinite loop bug if appointmentStartTimeMinutes <=0
  if (appointmentStartTimeMinutes <= 0) {
    appointmentStartTimeMinutes = 60;
  }
  availability.forEach(interval => {
    const startOfInterval = DateTime.fromJSDate(
      getStartOfIntervalRounded(interval.start, appointmentStartTimeMinutes)
    );
    // to account for 23:59, etc
    const endOfInterval = getEndOfIntervalRounded(interval.end);
    const roundedInterval = { start: startOfInterval.toJSDate(), end: endOfInterval.toJSDate() };
    const duration = { minutes: durationInMinutes };
    const appointmentInterval = { minutes: appointmentStartTimeMinutes };

    let startOfSubInterval = startOfInterval;
    while (startOfSubInterval <= endOfInterval) {
      const subInterval = {
        start: startOfSubInterval.toJSDate(),
        end: startOfSubInterval.plus(duration).toJSDate()
      };

      if (isSubInterval(subInterval, roundedInterval)) {
        startTimes.push(subInterval.start);
      }
      startOfSubInterval = startOfSubInterval.plus(appointmentInterval);
    }
  });

  // Since we don't return each individual capacity dock's availability and instead include them under the parent's ID
  // We want to remove any duplicates so we don't have duplicate slots showing to the end user
  return removeArrayDateDuplicates(startTimes);
}

export function getStartOfIntervalRounded(
  intervalStart: Date,
  appointmentStartTimeMinutes: number
): Date {
  /**
   * In the case the availability returns a start that is not divisible by the appointment start time minutes
   * We need to round it up to build the start times out correctly
   * i.e. 9:52am with a start time minutes setting of 30 needs to round to 10am to be correct
   * otherwise 9:52, 10:22, etc. would returned
   */

  // ensure appointmentStartTimeMinutes is a positive, non-zero number
  // to account for older data sets or the ability to set it to less than 0 in the database
  // we catch it here
  if (appointmentStartTimeMinutes <= 0) {
    appointmentStartTimeMinutes = 60;
  }

  const startOfInterval = DateTime.fromJSDate(intervalStart);

  // so we always round up.
  const newMinutes =
    Math.ceil(startOfInterval.minute / appointmentStartTimeMinutes) * appointmentStartTimeMinutes;

  const nearestFutureStart = startOfInterval.plus({ minute: newMinutes - startOfInterval.minute });

  const shouldRoundToNearestStartTimeMinute =
    startOfInterval.minute === 0 || startOfInterval < nearestFutureStart;

  const roundedStart = shouldRoundToNearestStartTimeMinute ? nearestFutureStart : startOfInterval;

  return roundedStart.toJSDate();
}

export function floorOfInterval(targetDateTime: Date, appointmentStartTimeMinutes: number): Date {
  if (appointmentStartTimeMinutes <= 0) {
    appointmentStartTimeMinutes = 60;
  }

  const luxonDateTime = DateTime.fromJSDate(targetDateTime);

  const newMinutes =
    Math.floor(luxonDateTime.minute / appointmentStartTimeMinutes) * appointmentStartTimeMinutes;

  const dateTimeFloor = luxonDateTime.set({ minute: newMinutes });

  return dateTimeFloor.toJSDate();
}

// Just private
function getEndOfIntervalRounded(endDate: Date) {
  const newDate = DateTime.fromJSDate(endDate);
  return newDate.minute === 59
    ? newDate.plus({ minute: 1 }).set({ second: 0, millisecond: 0 })
    : newDate;
}

export type Slot = {
  start: DateTime;
  docks: string[];
};

export function createSlotsFromAvailabilityResponse(
  availability: AvailabilityResponse[],
  timezone: string
): Record<string, Slot[]> {
  let i = 0;
  const avail = {};

  while (i < availability.length) {
    const item = availability[i];
    item.startTimes.forEach(startTime => {
      const startDateTime = DateTime.fromISO(startTime.toString()).setZone(timezone);
      const startDate = startDateTime.toFormat(LuxonDateTimeFormats.MonthDayYearSlashed);

      const slotData = {
        start: startDateTime,
        docks: []
      };

      if (!objPropExists(avail, startDate)) {
        avail[startDate] = [];
      }

      const existingAvailabilityItemIndex = avail[startDate].findIndex((availItem: Slot) => {
        return availItem.start.valueOf() === slotData.start.valueOf();
      });

      if (existingAvailabilityItemIndex < 0) {
        slotData.docks.push(item.dock.id);
        avail[startDate].push(slotData);
      } else {
        avail[startDate][existingAvailabilityItemIndex].docks.push(item.dock.id);
      }
    });
    i++;
  }

  return avail;
}

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
//
// Chronon - A date/time library for Nova
//
// A chronon is a hypothetical quantum of time: https://en.wikipedia.org/wiki/Chronon
//
//
// Design Goals:
//    * Pure functions - no mutations of inputs, no side-effects
//    * Enforce explicit timezone handling when possible
//
import { DateTime } from 'luxon';
import * as moment from 'moment-timezone'; //TODO: eventually replace with Luxon
import { ITimeInterval } from './interval';
import { isDate } from 'class-validator';

/**
 * Enum for DateTime Formats
 * @readonly
 * @enum { String }
 */
export enum DateTimeFormats {
  /** 20220101 */
  Date = 'YYYYMMDD',
  /** 2022-01-01 */
  DateDashed = 'YYYY-MM-DD',
  /** 2022/01/01 */
  DateSlashed = 'YYYY/MM/DD',
  /** 2022-01-01T16:30 */
  DateDashedTime = 'YYYY-MM-DDTHH:mm',
  /** 01012022 */
  MonthDayYear = 'MMDDYYYY',
  /** 01/01/2022 */
  MonthDayYearSlashed = 'MM/DD/YYYY',
  /** Thu 09/08/2022 */
  ShortDayMonthDayYearSlashed = 'ddd MM/DD/YYYY',
  /** 08:30 | 12:30 ~~> This one requires a specific task to replace, as it's used in a particular way by TimeEntry */
  Extended12HrTimeLeadingZeroHour = 'hh:mm',
  /** 5:30 ~~> Used internally by Drag'n'Drop, requires specific task to replace */
  Extended12HrTime = 'h:mm',
  /** 8:30 | 16:30 */
  Extended24HrTime = 'H:mm',
  /** 08:30 | 16:30 */
  Extended24HrTimeLeadingZeroHour = 'HH:mm',
  /** 16:30:15 | 06:30:20 */
  Extended24HrTimeLeadingZeroHourSeconds = 'HH:mm:ss',
  /** Jan 15, 2022 | Jan 1, 2022 */
  ShortDate = 'MMM D, YYYY',
  /** January 15th, 2022 | January 1st, 2022 */
  ShortDateFull = 'MMM Do, YYYY',
  /** January 15th, 2022 | January 1st, 2022 */
  LongDateFull = 'MMMM Do, YYYY',
  /** Monday | Tuesday | Wednesday */
  FullWeekday = 'dddd',
  /** Mon | Tue | Thu | */
  ShortWeekday = 'ddd',
  /** January | February | March */
  FullMonth = 'MMMM',
  /** Jan | Feb | Mar */
  ShortMonth = 'MMM',
  /** 2022 | 2023 */
  FullYear = 'YYYY',
  /** 1st | 2nd | 3rd */
  FullDayOfMonth = 'Do',
  /** January, 2022 | February 2022 */
  FullMonthYear = 'MMMM, YYYY',
  /** Thursday, January 1 | Sunday, October 5  */
  FullDayMonth = 'dddd, MMMM D',
  /** Thurs, Jan 1st, 2022 */
  ShortDayShortMonthFullYear = 'ddd, MMM Do, YYYY',
  /** Thursday, January 1st | Sunday, October 5th */
  FullDayMonthLong = 'dddd, MMMM Do',
  /** am | pm */
  AmPm = 'a',
  /** -7:00 | +2:00 */
  TimezoneOffset = 'Z',
  /** Mar 3, 2022  */
  ShortMonthYear = 'MMM Do, YYYY'
}

/**
 * Enum for DateTime Formats
 * https://github.com/moment/luxon/blob/master/docs/formatting.md
 * @readonly
 * @enum { String }
 */
export enum LuxonDateTimeFormats {
  /** 01/01/2022 */
  MonthDayYearSlashed = 'D',

  /** 01/01/2022 @ 5:30 PM */
  MonthDayYearSlashedTimeAMPM = 'D @ t',

  /** 01/01/22 @ 5:30PM */
  MonthDayYearSlashedTimeAMPMCompact = 'MM/dd/yy @ hh:mma',

  /** 01/01/2022 @ 17:30 */
  MonthDayYearSlashedTime24 = 'D @ T',

  /** 01/01/2022-0530 */
  MonthDayYearSlashedTimeNoSpace = 'MM/dd/yyyy-Hmm',

  /** 5:30 AM */
  Extended12HrTimeAMPM = 't',

  /** 05:30 */
  Extended12HrTimePadding = 'hh:mm',

  /** Aug 6, 2014 */
  DateWithAbbreviatedMonth = 'DD',

  /** 5:30 AM - EST */
  Extended12HrTimeAMPMWithAbbreviatedNamedOffset = 't - ZZZZ',

  /** 17:30 - PDT */
  Extended24HrTimeWithAbbreviatedNamedOffset = 'T - ZZZZ',

  /** 10 PM */
  Hour12 = 'h a',

  /** 22 */
  Hour24 = 'H',

  /** 17:30 */
  Extended24HrTime = 'T',

  /** 5:30 */
  Extended24HrTimeNoPadding = 'H:mm',

  /** Thursday, Sep 1, 2022 @ 6:00 PM */
  LongDateTimeShortMonth = 'EEEE, DD @ t',

  /** Thursday, Sep 1, 2022 @ 18:00 (EST) */
  LongDateTime24ShortMonthWithAbbreviatedNamedOffset = 'EEEE, DD @ T (ZZZZ)',

  /** Thursday, Sep 1, 2022 @ 6:00 PM (MDT) */
  LongDateTimeShortMonthWithAbbreviatedNamedOffset = 'EEEE, DD @ t (ZZZZ)',

  /** Thu, Sep 1, 2022 @ 18:00 (EST) */
  LongDateTime24ShortDayShortMonthWithAbbreviatedNamedOffset = 'EEE, DD @ T (ZZZZ)',

  /** Thu, Sep 1, 2022 @ 6:00 PM (MDT) */
  LongDateTimeShortDayShortMonthWithAbbreviatedNamedOffset = 'EEE, DD @ t (ZZZZ)',

  /** Thursday, Sep 1, 2022 @ 18:00 */
  LongDateTime24ShortMonth = 'EEEE, DD @ T',

  /** Thu, May 18, 2023 */
  LongDateShortMonth = 'EEE, DD',

  /** Dec 7 @ 10:30 AM */
  ShortMonthDayTimeAMPM = 'MMM d @ t',

  /** Dec 7 @ 17:30 */
  ShortMonthDayTime24 = 'MMM d @ T',

  /** America/New_York */
  IANANamedOffset = 'z',

  /** EST **/
  AbbreviatedNamedOffset = 'ZZZZ',

  /** Eastern Standard Time **/
  UnabbreviatedNamedOffset = 'ZZZZZ',

  // New v2 Formats
  /** 2022-01-01 */
  DateDashed = 'yyyy-MM-dd',

  /** Mon, 12/04/2023 - 08:00 AM (EST) */
  ShortDayDateSlashed12HourZone = 'EEE, D - hh:mm a (ZZZZ)',

  /** Mon, 12/04/2023 - 18:00 (EST) */
  ShortDayDateSlashed24HourZone = 'EEE, D - T (ZZZZ)',

  /** Mon, 12/04/2023 - 08:00 AM */
  ShortDayDateSlashed12Hour = 'EEE, D - hh:mm a',

  /** Mon, 12/04/2023 - 08:00 */
  ShortDayDateSlashed24Hour = 'EEE, D - HH:mm',

  /** Mon, 12/04/2023 */
  ShortDayDateSlashed = 'EEE, D',

  /** 05:30 AM */
  Extended12HrTimePaddingAMPM = 'hh:mm a',

  /** 3/20/2024 @ 03:30 */
  DateSlashedTimestamp24HourTime = 'D @ HH:mm',

  /** 3/20/2024 @ 03:30 PM */
  DateSlashedTimestamp12HourTimeAMPM = 'D @ hh:mm a',

  /** 3/20/2024 - 03:30 */
  DateSlashedDashTimestamp24HourTime = 'D - HH:mm',

  /** 3/20/2024 - 03:30 PM */
  DateSlashedDashTimestamp12HourTimeAMPM = 'D - hh:mm a'
}

export enum LuxonLocales {
  /** Jan 31, 2025 */
  enUS = 'en-US'
}

export enum Weekday {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday'
}

const supportedCountries = ['US', 'CA'];

// TODO: Some of these are already returned by moment.tz.zonesForCountry, so
// it's redundant to add them (but no harm since we are using a Set):
const additionalTimezones = [
  'America/Boise',
  'America/Chicago',
  'America/Denver',
  'America/Detroit',
  'America/Los_Angeles',
  'America/Louisville',
  'America/New_York',
  'America/North_Dakota/Beulah',
  'America/Phoenix',
  'America/Toronto',
  'Canada/Eastern',
  'Canada/Pacific',
  'CST6CDT',
  'PST8PDT',
  'US/Arizona',
  'US/Central',
  'US/Eastern',
  'US/Mountain',
  'US/Pacific',
  'US/Pacific-New'
];

export function addDays(inputDate: Date, days: number) {
  const date = new Date(inputDate);
  date.setDate(date.getDate() + days);
  return date;
}

export function addWeeks(args: { utcDate: Date; numberOfWeeks: number; timezone: string }): Date {
  const { utcDate, numberOfWeeks, timezone } = args;
  return moment(utcDate).tz(timezone).add(numberOfWeeks, 'week').toDate();
}

export function nextTimezoneWeekday(args: { weekday: Weekday; utcDate: Date; timezone: string }) {
  const { weekday, utcDate, timezone } = args;
  // TODO: Need to account for daylight saving time changes
  const utcDateInTimezone = moment(utcDate).tz(timezone);
  const utcDateForWeekday = moment(utcDate).tz(timezone).day(weekday);
  const isWeekdayGreater = utcDateForWeekday > utcDateInTimezone;
  return isWeekdayGreater ? utcDateForWeekday.toDate() : utcDateForWeekday.add(1, 'week').toDate();
}

// This next function is used to replicate an appt/reserve interval to a next weekday
// It uses nextTimezoneWeekday to get the next weekday and apply the interval start and end
// But it also checks if the end time has a date offset to apply
// if the interval starts at a day and it ends in the next, the nextTimezoneWeekday would
// apply the start and end time to the same date
export function nextTimezoneWeekdayInterval(args: {
  weekday: Weekday;
  utcStartDate: Date;
  utcEndDate: Date;
  timezone: string;
}): ITimeInterval {
  const { weekday, utcStartDate, utcEndDate, timezone } = args;
  const start = nextTimezoneWeekday({
    weekday,
    utcDate: utcStartDate,
    timezone
  });

  const daysOffset =
    DateTime.fromJSDate(utcEndDate).setZone(timezone).day -
    DateTime.fromJSDate(utcStartDate).setZone(timezone).day;

  const end = DateTime.fromJSDate(
    nextTimezoneWeekday({
      weekday,
      utcDate: utcEndDate,
      timezone
    })
  )
    .plus({
      days: daysOffset
    })
    .toJSDate();

  return {
    start,
    end
  };
}

export function getSupportedTimezones(): string[] {
  const zones = new Set<string>();

  for (const country of supportedCountries) {
    moment.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones);
  }

  additionalTimezones.forEach(zone => zones.add(zone));
  return [...zones].sort();
}

export function roundDownToNearestMinute(date: Date): Date {
  const dateTime = DateTime.fromJSDate(date);
  const startOfMinute = dateTime.startOf('minute');
  return startOfMinute.toJSDate();
}

export function minDate(date1: Date, date2: Date): Date {
  return date1 < date2 ? date1 : date2;
}

export function maxDate(date1: Date, date2: Date): Date {
  return date1 > date2 ? date1 : date2;
}

export function absDiffDatesInMillis(date1: Date, date2: Date): number {
  const start = DateTime.fromJSDate(date1);
  const end = DateTime.fromJSDate(date2);

  return Math.abs(end.diff(start, 'millisecond').toMillis());
}

export function formatDateTimeWithMilitarySupport(
  dateTime: string,
  timezone: string | null,
  twelveHoursFormat: LuxonDateTimeFormats,
  formatAsMilitary: boolean,
  twentyFourHoursFormat: LuxonDateTimeFormats
) {
  if (isDate(dateTime)) {
    dateTime = (dateTime as Date).toISOString();
  }
  // See supported ISO formats: https://moment.github.io/luxon/#/parsing?id=iso-8601
  const luxonDate = DateTime.fromISO(dateTime, {
    setZone: timezone === null, // Preserve timezone informed in the string, if none explicitly provided.
    zone: timezone
  });
  return luxonDate.toFormat(formatAsMilitary ? twentyFourHoursFormat : twelveHoursFormat, {
    locale: LuxonLocales.enUS
  });
}

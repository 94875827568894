import { AppointmentStatus } from './status';
import { breakWordsAtCaps, sortObjectByKeys } from './js-helpers';
import { DatabaseEntities } from './base';
import { FileUnits } from './file';

export const DEFAULT_APPOINTMENT_START_TIME_INTERVAL = 30;

export enum AppointmentStartTimeMinutes {
  EVERY_HOUR = 60,
  EVERY_THIRTY_MINUTES = 30,
  EVERY_TWENTY_MINUTES = 20,
  EVERY_FIFTEEN_MINUTES = 15,
  CUSTOM = 'custom'
}

export enum SettingGroups {
  REFERENCE_NUMBER = 'Reference Number ',
  APPOINTMENT_START_TIMES = 'Appointment Start Times',
  APPOINTMENT_NOTIFICATIONS = 'Appointment Notifications',
  APPOINTMENT_STATUSES = 'Appointment Statuses',
  CUSTOM_APPPOINTMENTS_GRID = 'Custom Appointments Grid',
  CARRIER_VIEW = 'Scheduling Portal',
  DOCK_SELECTION = 'Dock Selection',
  TIME_DISPLAY = 'Time Display',
  GATE_MANAGEMENT = 'Gate Management',
  OTHER = 'Other'
}

export const settingGroupDescriptions = Object.freeze({
  [SettingGroups.REFERENCE_NUMBER]: {
    description:
      "Reference Number is the reference you can request from carriers when they're scheduling an appointment.",
    link: 'https://opendock.zendesk.com/hc/en-us/articles/12988594213779-Using-the-Reference-Number-',
    linkLabel: ''
  },
  [SettingGroups.APPOINTMENT_START_TIMES]: {
    description: 'Determine the time intervals at which appointments will be allowed to start.',
    link: '',
    linkLabel: ''
  },
  [SettingGroups.APPOINTMENT_NOTIFICATIONS]: {
    description: 'Manage all types of appointment notifications for this {{entity}}.',
    link: '',
    linkLabel: ''
  },
  [SettingGroups.CARRIER_VIEW]: {
    description: 'Manage the Scheduling Portal settings for this {{entity}}',
    link: '',
    linkLabel: ''
  },
  [SettingGroups.DOCK_SELECTION]: {
    description: '',
    link: '',
    linkLabel: ''
  }
});

export interface IHasSettings {
  settings?: ISettings;
}

// These arrays must be in hierarchical order!
export const entitySettingsHierarchy = {
  Org: [],
  Warehouse: [DatabaseEntities.Org],
  Dock: [DatabaseEntities.Warehouse, DatabaseEntities.Org],
  LoadType: [DatabaseEntities.Warehouse, DatabaseEntities.Org]
};

export function makeEntitySettings(entityType, entity): ISettings {
  const hierarchy = entitySettingsHierarchy[entityType];
  entity.settings = entity.settings ?? {};

  if (!EntitySettings[entityType]) {
    return {};
  }

  const originalEntitySettings = { ...entity.settings };

  hierarchy.forEach(relation => {
    Object.keys(EntitySettings[entityType]).forEach(entitySettingKey => {
      // Should this just be lowercase first?
      const relationSettings = entity[relation.toLowerCase()]?.settings || {};

      const relationSettingExists =
        typeof relationSettings[entitySettingKey] !== 'undefined' &&
        relationSettings[entitySettingKey] !== null;

      const entitySettingExists =
        typeof entity.settings[entitySettingKey] !== 'undefined' &&
        entity.settings[entitySettingKey] !== null;

      // Get the keys of the settings that belongs to the same group
      const sameGroupSettings = Object.keys(Settings).filter(
        key => Settings[key].group === Settings[entitySettingKey].group
      );

      // Will be true if the entity setting has any value set for this group
      const entitySettingsGroupHasAnyValue = sameGroupSettings.some(key => {
        const setting = originalEntitySettings[key];
        return setting && setting.value !== null && setting.value !== 'undefined';
      });

      // Only override the entity setting with the next level org if there are not settings
      // set that are from the same group
      if (!entitySettingExists && relationSettingExists && !entitySettingsGroupHasAnyValue) {
        entity.settings[entitySettingKey] = relationSettings[entitySettingKey];
      }
    });
  });

  // Apply default values to missing settings
  Object.keys(EntitySettings[entityType]).forEach(s => {
    if (typeof entity.settings[s] === 'undefined' || entity.settings[s] === null) {
      entity.settings[s] = EntitySettings[entityType][s].defaultValue;
    }
  });

  return entity.settings;
}

export const test = {
  referenceNumberIsVisible: true,
  referenceNumberIsRequired: false,
  appointmentStartTimeMinutes: 30,
  appointmentStartTimeInterval: '30',
  muteAppointmentNotifications: 'thedefaultvalue'
};

export enum SettingInputType {
  String = 'string',
  Date = 'date',
  Bool = 'bool',
  Number = 'int',
  Email = 'email',
  Phone = 'phone',
  DropDown = 'dropdown',
  Document = 'document',
  ClockArray = 'clockArray',
  CheckboxArray = 'checkboxArray',
  LongString = 'longString'
}

export const customSettingInputTypes = [SettingInputType.ClockArray];

export interface IDropDownOption {
  label: string;
  value: string | number;
}

export type SettingsValueType = string | boolean | number | string[];

export interface ISettings {
  [k: string]: SettingsValueType;
}

export interface ISettingsMetadata {
  label?: string; // The human-readable label for the setting
  placeholder?: string;
  defaultValue: SettingsValueType; // The default value of the setting
  description?: string; // A short description of the setting
  descriptionEnabled?: string; // A description to be used when the value is truthy, not used if "description" set
  descriptionDisabled?: string; // A description to be used when the value is falsy, not used if "description" set
  inputType: SettingInputType; // The type of input a UI should display for the setting
  valueType: string; // Type for the value, IE Boolean, String, Number, etc
  documentOptions?: {
    aspectRatio: number | null;
    cropShape: 'rectangle' | 'circle' | null;
    showCropResult: boolean;
    modalHeaderText?: string | null;
  }; // If the setting is a document, provide options
  dropDownOptions?: Array<IDropDownOption>; // If the setting is a dropdown, provide options
  requirements?: Record<string, unknown>; // Required values of other settings to enable this setting
  overrides?: Record<string, unknown>;
  requiredFields?: Record<string, boolean>; // ENTITY fields that must be set (not null/undf) to enable this setting
  hiddenUntil?: Record<string, unknown>;
  isActive: boolean; // Whether the setting is currently in-use in the system
  group?: string; // A group key for organizing settings in UIs
  maxFileSize?: number; // Limit for doc upload settings
  fileSizeUnit?: FileUnits;
  errorMessage?: string;
  allowedMimeTypes?: string[];
  errorConditions?: Record<string, unknown>;
  booleanLabels?: string[];
  note?: string;
  booleanDropDownOptions?: {
    whenTrue: string;
    exampleTrue?: string;
    whenFalse: string;
    exampleFalse?: string;
  };
}

export type ISettingsMetadataCollection = {
  [k: string]: ISettingsMetadata;
};

export type ISettingsEntityCollection = {
  [k: string]: {
    [k: string]: ISettingsMetadata;
  };
};

export function makeGroupedSettings(entity: string) {
  const settings = EntitySettings[entity];
  const groupedSettings = { Other: {} };

  if (!settings) {
    return groupedSettings;
  }
  Object.entries(settings).forEach(([key, setting]) => {
    // Delete (don't return) inactive settings
    if (!setting.isActive) {
      delete settings[key];
    }

    if (setting.group && !groupedSettings[setting.group]) {
      groupedSettings[setting.group] = {};
    }

    // Group settings
    if (setting.group) {
      groupedSettings[setting.group][key] = setting;
    } else {
      groupedSettings.Other[key] = setting;
    }
  });

  const sorted = <Record<string, any>>sortObjectByKeys(groupedSettings);

  // If there are no "Other" settings, delete the key
  if (!Object.keys(sorted.Other).length) {
    delete sorted.Other;
  } else {
    // Move "Other" to the bottom
    const otherGrp = sorted.Other;
    delete sorted.Other;
    sorted.Other = otherGrp;
  }

  return sorted;
}

export function isSettingHidden(
  settingKey: string,
  setting: ISettingsMetadata,
  localSettings: ISettings
) {
  let isHidden = false;
  const hiddenUntil: { [key: string]: any } = setting.hiddenUntil;
  if (!hiddenUntil) {
    return isHidden;
  }
  Object.keys(hiddenUntil).forEach(key => {
    if (Array.isArray(hiddenUntil[key])) {
      if (!hiddenUntil[key].includes(localSettings[key])) {
        isHidden = true;
      }
    } else {
      if (typeof localSettings[key] === 'undefined' || hiddenUntil[key] !== localSettings[key]) {
        isHidden = true;
      }
    }
  });

  return isHidden;
}

export function shouldDisplayError(
  settingKey: string,
  setting: ISettingsMetadata,
  localSettings: ISettings
) {
  let shouldDisplayError = false;
  const errorConditions: { [key: string]: any } = setting.errorConditions;
  if (!errorConditions) {
    return shouldDisplayError;
  }
  Object.keys(errorConditions).forEach(key => {
    if (Array.isArray(errorConditions[key])) {
      errorConditions[key].forEach(conditionVal => {
        if (
          conditionVal === localSettings[key] ||
          JSON.stringify(localSettings[key]) === JSON.stringify(conditionVal)
        ) {
          shouldDisplayError = true;
        }
      });
    } else {
      if (errorConditions[key] === localSettings[key]) {
        shouldDisplayError = true;
      }
    }
  });

  return shouldDisplayError;
}

export function areRequiredFieldsSet(entity: object, setting: ISettingsMetadata): boolean {
  const requirements: { [key: string]: boolean } = setting.requiredFields;
  if (!requirements) {
    return true;
  }

  let allSet = true;

  Object.keys(requirements).forEach(r => {
    if (requirements[r]) {
      if (typeof entity[r] === 'undefined' || entity[r] === null) {
        allSet = false;
      }
    }
  });

  return allSet;
}

export function applyOverrides(settings: ISettings) {
  Object.keys(settings).forEach((settingName: string) => {
    const settingMetaData = Settings[settingName];
    const overrides: { [key: string]: any } = settingMetaData?.overrides;

    if (overrides) {
      Object.keys(overrides).forEach(r => {
        if (typeof settings[r] === 'undefined') {
          settings[r] = overrides[r];
        }
      });
    }
  });

  return settings;
}

export function areSettingRequirementsMet(
  settingKey: string,
  setting: ISettingsMetadata,
  localSettings: ISettings
) {
  let met = true;
  const requirements: { [key: string]: any } = setting.requirements;
  if (!requirements) {
    return true;
  }
  Object.keys(requirements).forEach(r => {
    if (Array.isArray(requirements[r])) {
      if (!requirements[r].includes(localSettings[r])) {
        met = false;
      }
    } else {
      if (typeof localSettings[r] === 'undefined' || requirements[r] !== localSettings[r]) {
        met = false;
      }
    }

    if (!met) {
      delete localSettings[settingKey];
    }
  });

  return met;
}

const notePrefix = '<strong>Note: </strong>';

// These settings should be considered immutable
// To remove a setting from the application set the "isActive" flag on it to false
export const Settings: ISettingsMetadataCollection = {
  referenceNumberIsVisible: {
    label: 'Include Reference Number field on all appointment forms?',
    defaultValue: true,
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    booleanDropDownOptions: {
      whenFalse: "No, don't include the Reference Number field",
      whenTrue: 'Yes, include the Reference Number field'
    },
    isActive: true,
    group: SettingGroups.REFERENCE_NUMBER,
    overrides: {
      referenceNumberIsRequired: false // That is, when referenceNumberIsRequired is UNDEFINED, force it being FALSE
    }
  },
  referenceNumberDisplayName: {
    label: 'Reference Number field name',
    defaultValue: 'Reference Number',
    description: 'The display name for the Reference Number field. Eg: "PO Number", "Shipment ID"',
    note: `${notePrefix} If left empty, the default name will be applied`,
    inputType: SettingInputType.String,
    valueType: 'string',
    requirements: { referenceNumberIsVisible: true },
    isActive: true,
    group: SettingGroups.REFERENCE_NUMBER
  },
  referenceNumberHelperText: {
    label: 'Reference Number helper text',
    placeholder: 'Reference Number associated with appointment',
    defaultValue: '',
    description:
      'Additional information to help fill in the Reference Number field.  Eg: "Reference Number should start with OD-XX"',
    note: `${notePrefix} If left empty, the default helper text will be applied`,
    inputType: SettingInputType.String,
    valueType: 'string',
    requirements: { referenceNumberIsVisible: true },
    isActive: true,
    group: SettingGroups.REFERENCE_NUMBER
  },
  referenceNumberIsRequired: {
    label: 'Make Reference Number a required field?',
    defaultValue: false,
    description: 'Require the Reference Number for all appointments being scheduled',
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    booleanDropDownOptions: {
      whenFalse: "No, don't make Reference Number a required field",
      whenTrue: 'Yes, make Reference Number a required field'
    },
    requirements: { referenceNumberIsVisible: true },
    isActive: true,
    group: SettingGroups.REFERENCE_NUMBER
  },
  referenceNumberIsUnique: {
    label: 'Require Unique Reference Numbers',
    defaultValue: false,
    description: 'Require unique Reference Numbers for all appointments being scheduled',
    note: `${notePrefix} Reference Numbers assigned to cancelled or no-show appointments may be reused on new appointments`,
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    booleanDropDownOptions: {
      whenFalse: "No, don't require unique Reference Numbers",
      whenTrue: 'Yes, require unique Reference Numbers'
    },
    requirements: { referenceNumberIsVisible: true },
    isActive: true,
    group: SettingGroups.REFERENCE_NUMBER
  },
  /**
   * @deprecated
   */
  appointmentStartTimeInterval: {
    label: 'Appointment Start Time Interval',
    defaultValue: `${DEFAULT_APPOINTMENT_START_TIME_INTERVAL}`,
    description: 'This is the interval (minutes) between when appointments can start',
    inputType: SettingInputType.DropDown,
    valueType: 'string',
    dropDownOptions: [
      {
        label: '15 Minutes',
        value: '15'
      },
      {
        label: '30 Minutes',
        value: '30'
      },
      {
        label: '60 Minutes',
        value: '60'
      },
      {
        label: '90 Minutes',
        value: '90'
      }
    ],
    isActive: false, // MEANS THIS IS DEPRECATED!
    group: SettingGroups.APPOINTMENT_START_TIMES
  },
  appointmentStartTimeMinutes: {
    label: 'Allow appointments for this {{entity}} to start at:',
    defaultValue: AppointmentStartTimeMinutes.EVERY_THIRTY_MINUTES,
    inputType: SettingInputType.DropDown,
    valueType: 'number | string',
    dropDownOptions: [
      {
        label: 'Top of the hour (:00)',
        value: AppointmentStartTimeMinutes.EVERY_HOUR
      },
      {
        label: 'Top and bottom of the hour (:00 and :30)',
        value: AppointmentStartTimeMinutes.EVERY_THIRTY_MINUTES
      },
      {
        label: 'Every Twenty Minutes (:00, :20, and :40)',
        value: AppointmentStartTimeMinutes.EVERY_TWENTY_MINUTES
      },
      {
        label: 'Every quarter of the hour (:00, :15, :30, and :45)',
        value: AppointmentStartTimeMinutes.EVERY_FIFTEEN_MINUTES
      },
      {
        label: 'Custom start times',
        value: 'custom'
      }
    ],
    isActive: true,
    group: SettingGroups.APPOINTMENT_START_TIMES
  },
  /**
   * TODO: This whole configuration is confusing, we should consider renaming it in the future to "ENABLE appointment
   *  notifications", as the name suggests. The initial idea was indeed "Mute", but we've changed later, which caused
   *  this config to be counter-intuitive.
   */
  muteAppointmentNotifications: {
    label: 'Enable appointment notifications for this {{entity}}?',
    defaultValue: false,
    note: `${notePrefix} Enabling this option does not impact notifications that are already disabled on the appointment level individually.`,
    descriptionEnabled: 'Appointment notifications will be sent',
    descriptionDisabled:
      'Appointment notifications will <strong>NOT</strong> be sent.<br/><strong>Caution:</strong> ALL appointment notifications will be muted for your {{entity}} for ALL users including carriers.',
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    booleanDropDownOptions: {
      whenFalse: 'No, disable appointment notifications',
      whenTrue: 'Yes, enable appointment notifications'
    },
    isActive: true,
    group: SettingGroups.APPOINTMENT_NOTIFICATIONS
  },
  /**
   * @deprecated
   */
  enableCustomAppointmentStartTimes: {
    label: 'Enable Custom Appointment Start Times',
    defaultValue: false,
    description: 'Enable the Custom Appointment Start Times setting',
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    isActive: false,
    group: SettingGroups.APPOINTMENT_START_TIMES
  },
  customAppointmentStartTimes: {
    label: 'Select each desired start time individually',
    defaultValue: [],
    inputType: SettingInputType.ClockArray,
    valueType: 'array',
    isActive: true,
    requirements: { appointmentStartTimeMinutes: 'custom' },
    group: SettingGroups.APPOINTMENT_START_TIMES,
    hiddenUntil: { appointmentStartTimeMinutes: 'custom' },
    errorMessage: 'Leaving this field empty will result in no availability on the schedule.',
    /* eslint-disable no-undefined */
    errorConditions: { customAppointmentStartTimes: [null, [], undefined] }
  },
  isLoadTypeDurationCarrierViewHidden: {
    label: 'Hide the load type duration?',
    defaultValue: false,
    description:
      'The duration is displayed on the Load Types select list booking an appointment in the Scheduling Portal',
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    booleanDropDownOptions: {
      whenFalse: "No, don't hide the Load Type duration",
      whenTrue: 'Yes, hide the Load Type duration'
    },
    isActive: true,
    group: SettingGroups.CARRIER_VIEW
  },
  customLogo: {
    label:
      'Upload your {{entity}} logo to display it in the Scheduling Portal and in all email notifications',
    defaultValue: null,
    inputType: SettingInputType.Document,
    valueType: 'document',
    isActive: true,
    group: SettingGroups.CARRIER_VIEW,
    maxFileSize: 800,
    fileSizeUnit: FileUnits.KB,
    documentOptions: {
      aspectRatio: 1,
      cropShape: 'circle',
      showCropResult: true
    },
    allowedMimeTypes: ['image/']
  },
  yardMap: {
    label: 'Upload your warehouse yard map to display it in the Scheduling Portal',
    defaultValue: null,
    inputType: SettingInputType.Document,
    valueType: 'document',
    isActive: true,
    group: SettingGroups.CARRIER_VIEW,
    maxFileSize: 6,
    fileSizeUnit: FileUnits.MB,
    documentOptions: {
      aspectRatio: null,
      cropShape: 'rectangle',
      showCropResult: false,
      modalHeaderText:
        'You can use the zoom feature and drag the image to adjust how the preview will appear for the scheduling users. ' +
        'They will be able  to click the preview to see the full image.'
    },
    allowedMimeTypes: ['image/']
  },
  appointmentCreationStatus: {
    label: 'Status for newly created appointments',
    defaultValue: AppointmentStatus.Scheduled,
    description: 'This only affects newly created appointments',
    inputType: SettingInputType.DropDown,
    valueType: 'string',
    isActive: true,
    group: SettingGroups.APPOINTMENT_STATUSES,
    dropDownOptions: [
      {
        label: AppointmentStatus.Scheduled,
        value: AppointmentStatus.Scheduled
      },
      {
        label: AppointmentStatus.Requested,
        value: AppointmentStatus.Requested
      }
    ]
  },
  mutedAppointmentNotifications: {
    label: 'Mute Appointment Notifications',
    defaultValue: [],
    description: 'A list of muted appointment notifications.',
    inputType: SettingInputType.CheckboxArray,
    valueType: 'array',
    isActive: true,
    group: SettingGroups.APPOINTMENT_NOTIFICATIONS
  },
  allowCarrierDockSelection: {
    label: 'Allow Dock selection in Scheduling Portal?',
    defaultValue: false,
    description: 'Allow Dock selection when booking an appointment in the Scheduling Portal',
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    booleanDropDownOptions: {
      whenFalse: "No, don't allow Dock selection",
      whenTrue: 'Yes, allow the Dock selection'
    },
    isActive: true,
    group: SettingGroups.DOCK_SELECTION
  },
  statusSucceedingArrived: {
    label: "The status that follows 'Arrived' is",
    defaultValue: AppointmentStatus.Completed,
    description: `This setting will allow the choice of an alternate '${breakWordsAtCaps(
      AppointmentStatus.InProgress
    )}' status between '${AppointmentStatus.Arrived}' and '${AppointmentStatus.Completed}'`,
    inputType: SettingInputType.DropDown,
    valueType: 'string',
    isActive: true,
    group: SettingGroups.APPOINTMENT_STATUSES,
    dropDownOptions: [
      {
        label: breakWordsAtCaps(AppointmentStatus.InProgress),
        value: AppointmentStatus.InProgress
      },
      {
        label: AppointmentStatus.Completed,
        value: AppointmentStatus.Completed
      }
    ]
  },
  enableMilitaryTime: {
    label: 'Display time in:',
    defaultValue: false,
    description:
      'Determine how time will be displayed across the platform for all users of this Org, including the appointments grid and reports.',
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    isActive: true,
    group: SettingGroups.TIME_DISPLAY,
    booleanDropDownOptions: {
      whenFalse: 'Regular time (12-hour)',
      exampleFalse: 'E.g. 7:00 AM / 7:00 PM',
      whenTrue: 'Military time (24-hour)',
      exampleTrue: 'E.g. 07:00 / 19:00'
    }
  },
  useNewGridTiles: {
    label: 'Customize appointment details',
    defaultValue: false,
    description: 'Enable to switch to the new customizable appointment details system',
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    isActive: true,
    group: SettingGroups.CUSTOM_APPPOINTMENTS_GRID
  },
  gridTileConfig: {
    label: 'Grid tile config',
    defaultValue: [],
    description: 'User configuration of how grid tiles will be displayed.',
    inputType: SettingInputType.CheckboxArray,
    valueType: 'array',
    isActive: true,
    group: SettingGroups.CUSTOM_APPPOINTMENTS_GRID
  },
  gateManagementRequiresGeofencing: {
    label: 'Enforce geofencing to require drivers to provide their real time location',
    defaultValue: false,
    requiredFields: { geolocation: true },
    description:
      'This setting asks for the drivers location and restricts them from checking-in when they are more than one mile away from your warehouse.',
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    isActive: true,
    group: SettingGroups.GATE_MANAGEMENT
  },
  gateManagementAdditionalInformationText: {
    label:
      'Would you like to show additional information to the drivers after they check in? (optional)',
    defaultValue: '',
    requiredFields: { geolocation: true },
    inputType: SettingInputType.LongString,
    valueType: 'string',
    isActive: true,
    group: SettingGroups.GATE_MANAGEMENT,
    placeholder: 'Insert message here'
  },
  rescheduleByCarrierForbidden: {
    label: 'Prevent rescheduling appointments in the Scheduling Portal?',
    defaultValue: false,
    description: 'Prevent carriers from rescheduling appointments. (optional)',
    inputType: SettingInputType.Bool,
    valueType: 'boolean',
    booleanDropDownOptions: {
      whenFalse: 'Allow rescheduling in the Scheduling Portal ',
      whenTrue: 'Prevent rescheduling in the Scheduling Portal'
    },
    isActive: true,
    group: SettingGroups.CARRIER_VIEW
  }
};

// TODO: Fix it so EntitySettings uses a Type, Interface or Class so we do not have a separate entity
//  to "type" our WarehouseSettings
export type WarehouseSettings = {
  appointmentStartTimeMinutes?: AppointmentStartTimeMinutes;
  // this needs to be better defined as an array or times as string
  customAppointmentStartTimes?: string[];
  isLoadTypeDurationCarrierViewHidden?: boolean;
  rescheduleByCarrierForbidden?: boolean;
  gateManagementRequiresGeofencing: boolean;
  gateManagementAdditionalInformationText: string;
  enableMilitaryTime: boolean;
  appointmentCreationStatus: string;
  statusSucceedingArrived: string;
  mutedAppointmentNotifications: string[];
  muteAppointmentNotifications: boolean;
  referenceNumberIsVisible: boolean;
  referenceNumberDisplayName: string;
  referenceNumberHelperText: string;
  referenceNumberIsRequired: boolean;
  referenceNumberIsUnique: boolean;
};

export const EntitySettings: ISettingsEntityCollection = {
  Org: {
    referenceNumberIsVisible: Settings.referenceNumberIsVisible,
    referenceNumberDisplayName: Settings.referenceNumberDisplayName,
    referenceNumberHelperText: Settings.referenceNumberHelperText,
    referenceNumberIsRequired: Settings.referenceNumberIsRequired,
    referenceNumberIsUnique: Settings.referenceNumberIsUnique,
    appointmentStartTimeInterval: Settings.appointmentStartTimeInterval,
    appointmentStartTimeMinutes: Settings.appointmentStartTimeMinutes,
    customAppointmentStartTimes: Settings.customAppointmentStartTimes,
    muteAppointmentNotifications: Settings.muteAppointmentNotifications,
    mutedAppointmentNotifications: Settings.mutedAppointmentNotifications,
    customLogo: Settings.customLogo,
    appointmentCreationStatus: Settings.appointmentCreationStatus,
    statusSucceedingArrived: Settings.statusSucceedingArrived,
    enableMilitaryTime: Settings.enableMilitaryTime
  },
  Warehouse: {
    appointmentStartTimeMinutes: Settings.appointmentStartTimeMinutes,
    customAppointmentStartTimes: Settings.customAppointmentStartTimes,
    isLoadTypeDurationCarrierViewHidden: Settings.isLoadTypeDurationCarrierViewHidden,
    customLogo: Settings.customLogo,
    yardMap: Settings.yardMap,
    useNewGridTiles: Settings.useNewGridTiles,
    gridTileConfig: Settings.gridTileConfig,
    gateManagementRequiresGeofencing: Settings.gateManagementRequiresGeofencing,
    gateManagementAdditionalInformationText: Settings.gateManagementAdditionalInformationText,
    enableMilitaryTime: Settings.enableMilitaryTime,
    appointmentCreationStatus: Settings.appointmentCreationStatus,
    statusSucceedingArrived: Settings.statusSucceedingArrived,
    mutedAppointmentNotifications: Settings.mutedAppointmentNotifications,
    muteAppointmentNotifications: Settings.muteAppointmentNotifications,
    referenceNumberIsVisible: Settings.referenceNumberIsVisible,
    referenceNumberDisplayName: Settings.referenceNumberDisplayName,
    referenceNumberHelperText: Settings.referenceNumberHelperText,
    referenceNumberIsRequired: Settings.referenceNumberIsRequired,
    referenceNumberIsUnique: Settings.referenceNumberIsUnique,
    rescheduleByCarrierForbidden: Settings.rescheduleByCarrierForbidden
  },
  Dock: {},
  User: {},
  Company: {},
  Appointment: {},
  LoadType: { allowCarrierDockSelection: Settings.allowCarrierDockSelection }
};

import type { Maybe } from './globals';
import type { DurationLike } from 'luxon';

/*
 * TODO(2024-08-23): @martini97: create migrations to update enum
 */
export enum CronjobsTask {
  ELASTIC_SEARCH_COMMIT_DATASTORE = 'elasticsearch-commit-datastore'
}

export enum CronjobsStatus {
  CREATED = 'created',
  STARTED = 'started',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  FAILED = 'failed'
}

export type CronjobsError = {
  message: Maybe<string>;
  stack: Maybe<string>;
  name: Maybe<string>;
  cause: Maybe<string>;
};

/*
 * Schedule to specify when a task will be executed.
 * Currently it only supports the timeout schedule type as it's the only one implemented.
 * The Cronjob Task will parse this value into a Javascript Date according to it's schedule type.
 */
export type CronjobsSchedule = number | DurationLike;

/*
 * The schedule type enum is base on the Nest's Task Scheduling:
 * https://docs.nestjs.com/techniques/task-scheduling
 * - CRON: task will be run repeatedly according to a cron expression, similar to unix cron.
 * - INTERVAL: task will be run repeatedly given an interval, similar to Javascript setInterval.
 * - TIMEOUT: task will be run once after the given timeout, similar to Javascript setTimeout.
 */
export enum CronjobScheduleType {
  CRON = 'cron',
  TIMEOUT = 'timeout',
  INTERVAL = 'interval'
}

export const CronjobsPendingStatuses = Object.freeze([CronjobsStatus.CREATED]);

import { AssetVisitEventType } from './asset-visit-event';

export function isAssetVisitPlanned(assetVisit) {
  return assetVisit.isPlanned ?? false;
}

export function isAssetVisitUnplannedAndUnresolved(assetVisitWithEvents) {
  return Boolean(
    !assetVisitWithEvents.isPlanned &&
      !assetVisitWithEvents.appointmentId &&
      !isAssetVisitCancelled(assetVisitWithEvents)
  );
}

export function isAssetVisitUnplannedAndResolved(assetVisitWithEvents) {
  return Boolean(
    !assetVisitWithEvents.isPlanned &&
      (assetVisitWithEvents.appointmentId ||
        assetVisitWithEvents.assetVisitEvents?.some(
          event => event.eventType === AssetVisitEventType.Canceled
        ))
  );
}

export function isAssetVisitCancelled(assetVisitWithEvents) {
  return assetVisitWithEvents.assetVisitEvents
    ?.map(event => event.eventType)
    .includes(AssetVisitEventType.Canceled);
}

export function getPlannedAssetVisits(assetVisits) {
  return assetVisits.filter(isAssetVisitPlanned);
}

export function getUnplannedAndUnresolvedAssetVisits(assetVisitsWithEvents) {
  return assetVisitsWithEvents.filter(isAssetVisitUnplannedAndUnresolved);
}

export function getResolvedAssetVisits(assetVisitsWithEvents) {
  return assetVisitsWithEvents.filter(assetVisit => isAssetVisitUnplannedAndResolved(assetVisit));
}

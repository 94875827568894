import { INovaEntity } from './base';
import { IHasOrgId } from './org';
import { GlobalLimits, IHasSchedule, IHasSettings, IWarehouse, sortBy } from './index';

export const MIN_CARRIER_LEADTIME_LIMIT_HRS = GlobalLimits.MAX_MIN_CARRIER_LEADTIME_LIMIT_HRS.value;
export const MAX_CARRIER_LEADTIME_LIMIT_HRS = GlobalLimits.MAX_MAX_CARRIER_LEADTIME_LIMIT_HRS.value;
export const MAX_CAPACITY_DOCKS = GlobalLimits.MAX_CAPACITY_DOCKS.value;
// All that's allowed at this point is really the schedule field
// orgId, id, and lastChangedBy are injected and will cause problems if we don't allow
export const ALLOWABLE_CHILD_DOCK_FIELDS = ['schedule', 'id', 'orgId', 'lastChangedBy'];

export const CAPACITY_CHILD_SLUG = ' --- CAPACITY #';

export interface IDock extends INovaEntity, IHasOrgId, IHasSchedule, IHasSettings {
  name: string;
  warehouseId: string;
  orgId?: string;
  loadTypeIds: string[];
  doorNumber?: string;
  instructions?: string;
  minCarrierLeadTime_hr?: number;
  minCarrierLeadTimeForUpdates_hr?: number;
  maxCarrierLeadTime_hr?: number;
  allowCarrierScheduling: boolean;
  allowOverBooking: boolean;
  capacityParentId?: string;
  capacityChildren?: IDock[];
  sortOrder?: number;

  warehouse?: IWarehouse;
}

export function isNormalDock(dock: IDock): boolean {
  return !isCapacityParticipant(dock);
}

export function isCapacityParticipant(dock: IDock): boolean {
  return Boolean(dock?.capacityParentId);
}

export function isCapacityParent(dock: IDock) {
  return isCapacityParticipant(dock) && dock.capacityParentId === dock.id;
}

export function isCapacityChild(dock: IDock) {
  return isCapacityParticipant(dock) && !isCapacityParent(dock);
}

export function isCapacityChildByName(dockName: string): boolean {
  return dockName.includes(CAPACITY_CHILD_SLUG);
}

export function getCapacityParentNameFromCapacityChildName(dockName: string): string {
  if (isCapacityChildByName(dockName)) {
    return dockName.split(CAPACITY_CHILD_SLUG)[0];
  }
  return dockName;
}

export function isRootDock(dock: IDock) {
  return isCapacityParent(dock) || isNormalDock(dock);
}

// Returns the "Capacity Number" (CN) of a Dock.
// Intuitively, this is the "parallel capacity" of a Dock, i.e.
// how many appointments can be booked in the same time interval.
//
// The CN of a Dock is defined as follows:
// - If it's a normal Dock, CN = 1
// - If it's a child Dock,  CN = 1
// - If it's a parent Dock, CN = 1 + number of child Capacity-Docks
//
// WARNING: assumes that the dock object was loaded with the
// capacityChildren relation/array correctly. Otherwise, the function
// will return null.
export function getCapacityNumber(dock: IDock): number {
  if (isCapacityParent(dock)) {
    return dock?.capacityChildren.length;
  }

  return 1;
}

export function sortCapacityChildren(dock: IDock): IDock[] {
  return sortBy(dock.capacityChildren, 'createDateTime') || [];
}

export function rearrangeDocksSortOrder(
  docks: IDock[],
  parents: IDock[],
  parentIndexFrom: number,
  parentIndexTo: number
): IDock[] {
  // Parents index doesn't count children so the real index needs to be found
  const realIndexFrom = docks.findIndex(el => el.id === parents[parentIndexFrom]?.id);
  const capacity = getCapacityNumber(parents[parentIndexFrom]);

  if (realIndexFrom < 0) {
    return docks;
  }
  // Remove the parent and its children (if it has) from the original dock
  const movedDocks = docks.splice(realIndexFrom, capacity);

  const targetIndex = docks.findIndex(el => el.id === parents[parentIndexTo]?.id);

  if (targetIndex < 0) {
    return docks;
  }

  const realIndexTo =
    parentIndexFrom < parentIndexTo
      ? targetIndex + getCapacityNumber(parents[parentIndexTo])
      : targetIndex;
  docks.splice(realIndexTo, 0, ...movedDocks);
  return docks;
}

export function getDockNameWithActiveStatus(dock: IDock) {
  return `${dock.name}${dock.isActive === false ? ' (Inactive)' : ''}`;
}

import * as moment from 'moment';
import * as IntervalLib from './interval';
import { insertStringIntoString, leftPadString } from './js-helpers';
import { isDefined, isInt } from 'class-validator';

// 'start'/'end' are strings that should contain a time only (no date) in 24-hr (military)
// time, the expected MomentJS format is: 'H:mm'.
export interface IClockSpan {
  start: string; // 'H:mm'
  end: string; // 'H:mm'
}

export interface IClockTime {
  text: string; // e.g. '12:00 am'
  value: string; // e.g. '23:59'
}

export const ClockSpanFormat = 'H:mm';
export const ClockSpanMultiFormats = [ClockSpanFormat, 'HH:mm'];
export const TwentyFourHourClockSpan: IClockSpan = { start: '0:00', end: '23:59' };

export function splitClockIntoUnitsOfTime(clockTime: string): {
  hour: number;
  minute: number;
} {
  const [hour, minute] = clockTime.split(':').map(Number);
  return { hour, minute };
}

export function getTwentyFourHourClockSpan(): IClockSpan {
  return { start: '0:00', end: '23:59' };
}

export type IClockSpanList = IClockSpan[];

export function clockToMoment(clock: string): moment.Moment {
  const m = typeof clock === 'string' && moment(clock, ClockSpanMultiFormats, true);
  // We are not allowing clock '24:00' despite the fact moment says it is valid
  return m instanceof moment && m.isValid() && !clock?.startsWith('24:') ? m : null;
}

export function clockIsValid(clock: string): boolean {
  return typeof clock === 'string' && Boolean(clockToMoment(clock)?.isValid());
}

// Returns: 'null' if clock span is valid, otherwise a detailed error message.
export function validateClockSpan(i: IClockSpan): null | string {
  if (i === null || typeof i === 'undefined' || typeof i !== 'object') {
    return `ClockSpan incorrect type: '${i}'`;
  }

  const keys = Object.keys(i);
  const validKeys = ['start', 'end'];

  for (const key of validKeys) {
    if (!keys.includes(key)) {
      return `ClockSpan missing key: '${key}'`;
    }
  }

  for (const key of keys) {
    if (!validKeys.includes(key)) {
      return `ClockSpan has an invalid key: '${key}'`;
    }

    const val = i[key];
    if (typeof val !== 'string') {
      return `ClockSpan '${key}' should be a string: '${val}'`;
    }

    if (!clockIsValid(val)) {
      return `ClockSpan '${key}' should be a clock time (format ${ClockSpanFormat}): '${val}'`;
    }
  }

  if (clockToMoment(i.start).isAfter(clockToMoment(i.end))) {
    return `ClockSpan 'start' (${i.start}) is later than 'end' (${i.end})`;
  }

  return null;
}

// Assumes input 'clock' is a valid clock time as per clockIsValid().
// Input 'dayOfWeek' should be ISO standard day number: monday = 1, tuesday = 2, etc...
export function clockTo2001Format(clock: string, dayOfWeek: number): Date {
  const timeStr = clockToMoment(clock).format('HH:mm'); // Ensure leading zero on the time part
  const moment2001 = moment(new Date(`2001-01-0${dayOfWeek}T${timeStr}:00.000Z`));

  if (!moment2001.isValid()) {
    throw new Error(`Core: clockTo2001Format(): Invalid dayOfWeek ${dayOfWeek} or clock ${clock}`);
  }

  return moment2001.toDate();
}

// Assumes the input 'cs' is a valid IClockSpan.
// Inputs 'startDayOfWeek' and 'endDayOfWeek' should be ISO standard day number: monday = 1, tuesday = 2, etc...
export function clockSpanToInterval(
  cs: IClockSpan,
  startDayOfWeek: number,
  endDayOfWeek?: number
): IntervalLib.ITimeInterval {
  return IntervalLib.fromDates(
    clockTo2001Format(cs.start, startDayOfWeek),
    clockTo2001Format(cs.end, endDayOfWeek || startDayOfWeek)
  );
}

export function clocksDontOverlap(cs1: IClockSpan, cs2: IClockSpan, dayOfWeek = 1): boolean {
  return IntervalLib.doesntOverlap(
    clockSpanToInterval(cs1, dayOfWeek),
    clockSpanToInterval(cs2, dayOfWeek)
  );
}

export function clocksOverlap(cs1: IClockSpan, cs2: IClockSpan, dayOfWeek = 1): boolean {
  return IntervalLib.overlaps(
    clockSpanToInterval(cs1, dayOfWeek),
    clockSpanToInterval(cs2, dayOfWeek)
  );
}

// Given an clock span list, "stitch together" or merge adjacent intervals that
// have a small enough gap between them into one clockspan, i.e.
// [-----------] |----------------|     becomes
// [------------------------------]
export function mergeAdjacentSpans(
  clockSpans: IClockSpanList,
  gapThreshold_ms = 1000
): IClockSpanList {
  const intervals = IntervalLib.sortByStart(clockSpanListToIntervalList(clockSpans));

  if (gapThreshold_ms && typeof gapThreshold_ms !== 'number') {
    throw new Error(`Core: mergeAdjacentSpans(): invalid gapThreshold_ms ${gapThreshold_ms}`);
  }
  const mergedIntervals = IntervalLib.mergeSmallGaps(intervals, gapThreshold_ms);

  return intervalListToClockSpanList(mergedIntervals);
}

export function intervalToClockSpan(interval: IntervalLib.ITimeInterval): IClockSpan {
  const mStart = interval?.start && moment(interval.start);
  const mEnd = interval?.end && moment(interval.end);

  if (mStart?.isValid() && mEnd?.isValid()) {
    const clockSpan = {
      start: mStart.utcOffset(0).format(ClockSpanFormat),
      end: mEnd.utcOffset(0).format(ClockSpanFormat)
    };
    const validateClockSpanMessage = validateClockSpan(clockSpan);
    if (!validateClockSpan(clockSpan)) {
      return clockSpan;
    }
    throw new Error(`Core: intervalToClockSpan(): ${validateClockSpanMessage}`);
  }
  throw new Error('Core: intervalToClockSpan(): invalid interval');
}

export function clockSpanListToIntervalList(
  clockSpanList: IClockSpanList,
  dayOfWeek = 1
): IntervalLib.ITimeIntervalList {
  return clockSpanList.map(span => clockSpanToInterval(span, dayOfWeek));
}

export function intervalListToClockSpanList(
  intervalList: IntervalLib.ITimeIntervalList
): IClockSpanList {
  return intervalList.map(interval => intervalToClockSpan(interval));
}

export function makeFullDayClockOptions(
  intervalMinutesLength = 30,
  useMilitaryTime = false
): Array<IClockTime> {
  if (!Number.isInteger(intervalMinutesLength) || intervalMinutesLength < 1) {
    throw new Error('intervalMinutesLength should be an integer greater than or equal 1');
  }

  const items = [];
  const start = clockToMoment('0:00');
  const end = clockToMoment('23:59');
  while (start.isSameOrBefore(end)) {
    items.push({
      text: start.clone().format(useMilitaryTime ? 'H:mm' : 'h:mm a'),
      value: start.clone().format('H:mm')
    });
    start.add(intervalMinutesLength, 'minutes');
  }
  items.push({ text: useMilitaryTime ? '0:00' : '12:00 am', value: '23:59' });

  return items;
}

export function getFullDayClockOptionsGroupedByHour(intervalMinutes = 30, useMilitaryTime = false) {
  const options = [...makeFullDayClockOptions(intervalMinutes, useMilitaryTime)];
  return options;
}

export function clockAsInt(clockTime: string): number {
  return parseInt(clockTime.replace(':', ''), 10);
}

export const intToClock = (integer: number): string => {
  if ((!integer && integer !== 0) || !isInt(integer) || integer >= 2400 || integer < 0) {
    throw new Error(`Invalid integer conversion '${integer}'`);
  }
  const timeAsInt = integer.toString();
  return `${timeAsInt.slice(0, -2)}:${timeAsInt.slice(-2)}`;
};

export function minutesSinceMidnightToClock(minutes: number, leadingZeroHour = false): string {
  let hours: string | number = Math.floor(minutes / 60);
  if (leadingZeroHour) {
    hours = leftPadString(hours, 2, '0', 2);
  }
  let remainder = leftPadString(minutes % 60, 2, '0', 2);
  // Instead of 24:00, we need to format to 23:59 so it fits the expected 'end of day' time in Nova
  if (hours === 24) {
    hours = 23;
    remainder = '59';
  }
  return `${hours}:${remainder}`;
}

export function formatClockTime(time: string | number, isMilitaryTimeEnabled?: boolean): string {
  if (!isDefined(time) || (!isInt(time) && !time)) {
    return null;
  }

  // Helper functions
  function formatIntoClockTime(time: string | number) {
    time = String(time);
    if (!time.includes(':') && time.length > 2) {
      time = insertStringIntoString(time, ':', 2);
    }
    return time;
  }
  function getTimeParts(time) {
    const parts = time.split(':');
    const hour = parts[0];
    const minute = parts?.[1] ?? '0';
    return { hour, minute };
  }
  function padHour(hour) {
    const hourAsInt = parseInt(hour, 10);
    const hourNeedsPadding = hourAsInt < 10 && hour.substring(0, 1) !== '0';
    return hourNeedsPadding ? pad(hour) : hour;
  }
  function handleCarryOver(hour, minute) {
    hour = padHour(hour);
    const hourAsInt = parseInt(hour, 10);
    const minuteAsInt = parseInt(minute, 10);

    const hourNeedsCarryOver =
      (!isMilitaryTimeEnabled && hourAsInt > 12) || (isMilitaryTimeEnabled && hourAsInt > 23);
    if (hourNeedsCarryOver) {
      const carryOver = hour.substring(1);
      hour = pad(hour.substring(0, 1));
      if (minuteAsInt > 59) {
        minute = minute.substring(0, 1);
      }
      minute = carryOver + minute;
    }
    return { hour, minute };
  }
  function finalizeMinute(minute) {
    const minuteAsInt = parseInt(minute, 10);

    if (minuteAsInt < 10) {
      minute = pad(minute);
    }

    if (minuteAsInt > 59) {
      minute = '59';
    }
    return minute;
  }
  function pad(str, direction = 'left', pad = '0') {
    return direction === 'left' ? pad + str : str + pad;
  }

  // Process Time
  time = formatIntoClockTime(time);
  let { hour, minute } = getTimeParts(time);
  ({ hour, minute } = handleCarryOver(hour, minute));
  minute = finalizeMinute(minute);

  return `${hour.slice(-2)}:${minute.slice(-2)}`;
}
